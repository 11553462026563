import React, { useState } from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import IconPlay from '../../atoms/Icons/IconPlay'

const Wrapper = styled.div`
   border: 2px solid ${({ theme }) => theme.color.primaryLight};
   border-radius: 12px;
   height: 700px;
   box-shadow: ${({ theme }) => theme.shadow1.normal};
   padding: 2rem;
   @media (max-width: ${({ theme }) => theme.breakpoint.xl}) {
      height: 600px;
   }
   @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
      height: 500px;
   }
   @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
      height: 400px;
   }
   @media (max-width: ${({ theme }) => theme.breakpoint.sm}) {
      height: 300px;
   }
`

const Inner = styled.div`
   width: 100%;
   height: 100%;
   border-radius: 12px;
   background-color: ${({ theme }) => theme.color.grayLight};
   overflow: hidden;
   position: relative;
   .gatsby-image-wrapper {
      height: 100%;
   }
`
const PlayWrapper = styled.div`
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   z-index: 99;
   cursor: pointer;
   svg {
      overflow: visible;
      transition: ${({ theme }) => theme.transition};
      &:hover {
         transform: scale(1.2);
      }
   }
`

const Overlay = styled.div`
   position: absolute;
   height: 100%;
   width: 100%;
   left: 0;
   top: 0;
   background-color: ${({ theme }) => theme.color.grayLight}50;
`

const Video = () => {
   const [play, setPlay] = useState(false)
   const clickHandler = () => setPlay(!play)
   return (
      <Wrapper>
         <Inner>
            {!play ? (
               <>
                  <StaticImage
                     src="../../../assets/images/screen.png"
                     alt="wide"
                     layout="fullWidth"
                     objectPosition="left top"
                  />
                  <Overlay />
                  <PlayWrapper onClick={clickHandler}>
                     <IconPlay />
                  </PlayWrapper>
               </>
            ) : (
               <iframe
                  width="100%"
                  height="100%"
                  src="https://www.youtube-nocookie.com/embed/VLy2IQsNaUg"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
               ></iframe>
            )}
         </Inner>
      </Wrapper>
   )
}

export default Video
