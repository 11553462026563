import React, { useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import Heading from '../../atoms/Heading/Heading'
import IconDropdownArrow from '../../atoms/Icons/IconDropdownArrow'
import IconDropdownClose from '../../atoms/Icons/IconDropdownClose'
import Paragraph from '../../atoms/Paragraph/Paragraph'

const Item = styled.div`
   ${({ numbers }) =>
      !numbers &&
      css`
         background-color: ${({ theme }) => theme.color.primary};
         border-radius: 12px;
      `}
`
const ItemTitle = styled.div`
   display: flex;
   align-items: center;
   gap: 2rem;
   padding: 2rem;
   background-color: ${({ theme }) => theme.color.primary};
   border-radius: 12px;
   cursor: pointer;
   transition: ${({ theme }) => theme.transition};
   &:hover,
   &.active {
      box-shadow: ${({ theme }) => theme.shadow1.hover};
   }
   svg {
      margin-left: auto;
      flex-shrink: 0;
   }
   ${({ numbers }) =>
      numbers &&
      css`
         background-color: ${({ theme }) => theme.color.white};
         color: ${({ theme }) => theme.color.black};
         padding: 1rem;
         &:hover {
            background-color: ${({ theme }) => theme.color.grayLight};
         }
         &:hover,
         &.active {
            box-shadow: none;
         }
      `}
`
const NumberWrapper = styled.span`
   width: 3.5rem;
   height: 3.5rem;
   display: flex;
   align-items: center;
   justify-content: center;
   background-color: ${({ theme }) => `${theme.color.primary}`};
   color: ${({ theme }) => `${theme.color.white}`};
   border-radius: 3.5rem;
   font-size: 1.5rem;
   font-weight: 500;
   flex-shrink: 0;
`
const ContentWrapper = styled.div`
   overflow: hidden;
   height: 0;
   transition: ${({ theme }) => theme.transition};
   &.active {
      height: ${({ height }) => `calc(${height}px + 1rem)`};
   }
`
const ItemContent = styled.div`
   padding: 0 2rem 2rem 2rem;
   background-color: ${({ theme }) => `${theme.color.primary}`};
   color: ${({ theme }) => `${theme.color.white}`};
   border-radius: 12px;
   ${({ numbers }) =>
      numbers &&
      css`
         background-color: transparent;
         padding: 1rem 1rem 0 6.5rem;
         @media (max-width: ${({ theme }) => theme.breakpoint.sm}) {
            padding-left: 1rem;
         }
      `}
`

const FaqItem = ({ title, children, numbers, number }) => {
   const ref = useRef('0')
   const [active, setActive] = useState(false)
   const [height, setHeight] = useState('0')
   const clickHandler = (e) => {
      setHeight(ref.current.offsetHeight)
      setActive(!active)
   }
   return (
      <Item numbers={numbers}>
         <ItemTitle
            numbers={numbers}
            className={active ? 'active' : ''}
            onClick={clickHandler}
         >
            {numbers && <NumberWrapper>{number}</NumberWrapper>}
            <Heading as="h3" size="h4" color={`${numbers ? 'black' : 'white'}`}>
               {title}
            </Heading>
            {active ? (
               <IconDropdownClose color={`${numbers ? 'black' : 'white'}`} />
            ) : (
               <IconDropdownArrow color={`${numbers ? 'black' : 'white'}`} />
            )}
         </ItemTitle>
         <ContentWrapper className={active ? 'active' : ''} height={height}>
            <ItemContent numbers={numbers} ref={ref}>
               <Paragraph
                  color={numbers ? 'black' : 'white'}
                  dangerouslySetInnerHTML={{ __html: children }}
               />
            </ItemContent>
         </ContentWrapper>
      </Item>
   )
}

export default FaqItem
