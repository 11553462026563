import React from 'react'
import { useTheme } from 'styled-components'
import PropTypes from 'prop-types'

const IconPlay = ({ color }) => {
   const theme = useTheme()
   return (
      <svg
         width="96"
         height="96"
         viewBox="0 0 96 96"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
      >
         <circle
            className="drop-shadow"
            cx="48"
            cy="48"
            r="48"
            fill={theme.color['white']}
         />
         <path
            d="M36 64.1451V31.8543C36 28.8801 39.0823 27.03 41.5308 28.5345L66.185 43.6833C68.5277 45.1228 68.6208 48.6363 66.3579 50.2097L41.7037 67.3518C39.2624 69.0492 36 67.2151 36 64.1451Z"
            stroke={theme.color[color]}
            strokeWidth="3"
         />
      </svg>
   )
}

export default IconPlay

IconPlay.propTypes = {
   color: PropTypes.string,
}

IconPlay.defaultProps = {
   color: 'primary',
}
