import React from 'react'
import styled from 'styled-components'
import Container from '../../atoms/Container/Container'
import Heading from '../../atoms/Heading/Heading'
import SectionHeader from '../../atoms/SectionHeader/SectionHeader'
import Paragraph from '../../atoms/Paragraph/Paragraph'
import IconBox from '../../molecules/IconBox/IconBox'
import iconSheet from '../../../assets/images/sheet.svg'
import iconShare from '../../../assets/images/sheet-share.svg'
import iconId from '../../../assets/images/id.svg'
import iconTree from '../../../assets/images/tree.svg'
import icon1 from '../../../assets/images/1.svg'
import icon2 from '../../../assets/images/2.svg'
import icon3 from '../../../assets/images/3.svg'
import iconOk from '../../../assets/images/ok.svg'
import Video from '../../molecules/Video/Video'
import Tips from '../Tips/Tips'

const Wrapper = styled.section`
   padding: 7.5rem 0;
   @media (max-width: ${({ theme }) => theme.breakpoint.sm}) {
      padding: 5rem 0;
   }
   @media (max-width: 768px) and (orientation: landscape) {
      padding: 5rem 0;
   }
`
const ContentWrapper = styled.div`
   display: flex;
   flex-direction: column;
   align-items: center;
   text-align: center;
`
const StyledParagraph = styled(Paragraph)`
   max-width: 600px;
`

const StepsWrapper = styled.div`
   display: grid;
   grid-template-columns: repeat(4, 1fr);
   gap: 2rem;
   margin: 5rem 0;
   @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
      grid-template-columns: repeat(2, 1fr);
   }
   @media (max-width: ${({ theme }) => theme.breakpoint.sm}) {
      grid-template-columns: 1fr;
   }
`

const HowItWorks = () => (
   <Wrapper id="jak-to-dziala">
      <Container>
         <SectionHeader>
            <ContentWrapper>
               <SectionHeader>
                  <Heading>Jak to działa?</Heading>
               </SectionHeader>
               <StyledParagraph>
                  Żeby wygenerować swoje Drzewko wystarczy, że przygotujesz
                  arkusz według{' '}
                  <a
                     href="https://docs.google.com/spreadsheets/d/1drjUpfh3Ks2wzzFQtQ_YwIkL-VzDkbNLsxHfhfS_Wd4/edit?usp=sharing"
                     target="_blank"
                     rel="noreferrer noopener"
                  >
                     tego&nbsp;szablonu
                  </a>
                  , udostępnisz go i wkleisz jego ID do formularza.
               </StyledParagraph>
            </ContentWrapper>
         </SectionHeader>
         <StepsWrapper>
            <IconBox title="Przygotuj arkusz">
               <img src={iconSheet} alt="Przygotuj arkusz" />
               <img src={icon1} alt="1" />
            </IconBox>
            <IconBox title="Udostępnij arkusz">
               <img src={iconShare} alt="Udostępnij arkusz" />
               <img src={icon2} alt="2" />
            </IconBox>
            <IconBox title="Skopiuj ID i wklej do formularza">
               <img src={iconId} alt="Skopiuj ID i wklej do formularza" />
               <img src={icon3} alt="3" />
            </IconBox>
            <IconBox title="Wygeneruj Drzewko">
               <img src={iconTree} alt="Wygeneruj Drzewko" />
               <img src={iconOk} alt="ok" />
            </IconBox>
         </StepsWrapper>
         <Video />
         <Tips />
      </Container>
   </Wrapper>
)

export default HowItWorks
