import React from 'react'
import styled from 'styled-components'
import SectionHeader from '../../atoms/SectionHeader/SectionHeader'
import Heading from '../../atoms/Heading/Heading'
import Faq from '../../molecules/Faq/Faq'
import Box from '../../atoms/Box/Box'
import Form from '../../molecules/Form/Form'

const Wrapper = styled.div`
   display: grid;
   grid-template-columns: 1fr 1fr;
   gap: 6rem;
   @media (max-width: ${({ theme }) => theme.breakpoint.xl}) {
      gap: 3rem;
   }
   @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
      display: flex;
      flex-direction: column;
   }
`
const FaqWrapper = styled.div`
   @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
      order: 2;
   }
`

const FormWrapper = styled.div`
   @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
      order: 1;
   }
`

const StyledBox = styled(Box)`
   position: sticky;
   top: 0;
   left: 0;
   @media (max-width: ${({ theme }) => theme.breakpoint.xl}) {
      padding: 4rem;
   }
   @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
      padding: 3rem;
   }
   @media (max-width: ${({ theme }) => theme.breakpoint.sm}) {
      padding: 2rem;
   }
`

const CreateTreeModal = () => {
   return (
      <>
         <SectionHeader className="hide-lg">
            <Heading size="h2" as="h2">
               Wskazówki!
            </Heading>
         </SectionHeader>
         <Wrapper>
            <FaqWrapper>
               <SectionHeader className="show-lg">
                  <Heading size="h3" as="h2">
                     Wskazówki!
                  </Heading>
               </SectionHeader>
               <Faq
                  numbers
                  items={[
                     {
                        title: 'Przygotuj arkusz',
                        content:
                           'Skopiuj <a href="https://docs.google.com/spreadsheets/d/1drjUpfh3Ks2wzzFQtQ_YwIkL-VzDkbNLsxHfhfS_Wd4/edit?usp=sharing" target="_blank" rel="noreferrer noopener">ten arkusz</a>. Nie zmieniaj nagłówków kolumn i pamiętaj, że każda kategoria musi mieć swoje unikalne ID. Kategorie oznacz poziomem zagnieżdżenia i dodawaj je z zachowaniem kolejności i hierarchii.',
                     },
                     {
                        title: 'Udostępnij arkusz',
                        content:
                           'Udostępnij arkusz jako "Każdy użytkownik internetu mający ten link może wyświetlać". Bez tego Drzewko nie zadziała.',
                     },
                     {
                        title: 'Skopiuj ID i wklej do formularza',
                        content:
                           'Skopiuj ID wcześniej udostępnionego arkusza. Znajdziesz je w pasku przeglądarki. Skopiowane ID wklej do formularza i wygeneruj swoje Drzewko.',
                     },
                  ]}
               />
            </FaqWrapper>

            <FormWrapper>
               <SectionHeader className="show-lg">
                  <Heading size="h3" as="h2">
                     Wklej ID arkusza i wygeneruj drzewko!
                  </Heading>
               </SectionHeader>
               <StyledBox big>
                  <Form />
               </StyledBox>
            </FormWrapper>
         </Wrapper>
      </>
   )
}

export default CreateTreeModal
