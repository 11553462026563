import React from 'react'
import styled, { css } from 'styled-components'

const Wrapper = styled.div`
   width: 100%;
   display: none;
   display: none;
   ${({ active }) =>
      active &&
      css`
         display: block;
      `}
`

const TabsItem = ({ active, index, children }) => (
   <Wrapper active={active === index ? true : false}>{children}</Wrapper>
)

export default TabsItem
