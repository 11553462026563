import * as React from 'react'
import { myContext } from '../../provider'
import Seo from '../components/Seo/Seo'
import HeroImage from '../components/organisms/HeroImage/HeroImage'
import Layout from '../components/Layout/Layout'
import MainBox from '../components/organisms/MainBox/MainBox'
import HowItWorks from '../components/organisms/HowItWorks/HowItWorks'
import FaqHome from '../components/organisms/FaqHome/FaqHome'
const IndexPage = ({ location }) => {
   return (
      <>
         <Seo />
         <myContext.Consumer>
            {(context) => (
               <Layout url={location.pathname}>
                  <HeroImage />
                  <MainBox title="Czym jest drzewko?">
                     Arkusze z drzewem kategorii bywają nieczytelne, a dla
                     klientów niezrozumiałe. Drzewko rozwiązuje ten problem
                     generując na podstawie Google Sheets czytelną nawigację,
                     którą możesz wysłać klientowi.
                  </MainBox>
                  <HowItWorks />
                  <FaqHome />
               </Layout>
            )}
         </myContext.Consumer>
      </>
   )
}

export default IndexPage
