import React from 'react'
import styled from 'styled-components'
import Box from '../../atoms/Box/Box'
import Paragraph from '../../atoms/Paragraph/Paragraph'

const Wrapper = styled.div`
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   height: 100%;
   color: ${({ theme }) => theme.color.white};
`
const StyledParagraph = styled(Paragraph)`
   font-weight: 500;
   color: ${({ theme }) => theme.color.white};
   margin-bottom: 2rem;
`
const Icons = styled.div`
   display: flex;
   justify-content: space-between;
   align-items: flex-end;
`

const IconBox = ({ children, title }) => (
   <Box color="black" hover>
      <Wrapper>
         <StyledParagraph>{title}</StyledParagraph>
         <Icons>{children}</Icons>
      </Wrapper>
   </Box>
)

export default IconBox
