import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'

const Wrapper = styled.ul`
   width: 100%;
   padding: 0 5rem 0 5rem;
   margin-bottom: 5rem;
   border-bottom: 2px solid ${({ theme }) => theme.color.blackLight};
   list-style-type: none;
   display: flex;
   gap: 5rem;
   @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
      padding: 0 3rem;
      gap: 3rem;
   }
   @media (max-width: ${({ theme }) => theme.breakpoint.sm}) {
      padding: 0 2rem;
      gap: 2rem;
   }
`
const Item = styled.li`
   cursor: pointer;
   padding-bottom: 1rem;
   transition: ${({ theme }) => theme.transition};
   position: relative;
   &:after {
      content: '';
      display: block;
      position: absolute;
      height: 1.5px;
      width: 100%;
      left: 0;
      bottom: -2px;
      transition: ${({ theme }) => theme.transition};
   }
   ${({ active }) =>
      active &&
      css`
         text-shadow: 0 0 0.65px ${({ theme }) => theme.color.white},
            0 0 0.65px ${({ theme }) => theme.color.white};
         &:after {
            background-color: ${({ theme }) => theme.color.primary};
         }
      `};
`
const TabsNav = ({ items, clickFn, active }) => {
   return (
      <Wrapper>
         {items.map((item, index) => (
            <Item
               onClick={clickFn}
               key={item}
               id={index}
               active={active === index ? true : false}
               title={item}
            >
               {item}
            </Item>
         ))}
      </Wrapper>
   )
}

export default TabsNav

TabsNav.propTypes = {
   items: PropTypes.array.isRequired,
}
