import React from 'react'
import styled, { css } from 'styled-components'
import Button from '../Button/Button'
import IconArrow from '../../atoms/Icons/IconArrow'

const Wrapper = styled(Button)`
   ${({ text }) =>
      text &&
      css`
         padding: 0;
         &:hover {
            background-color: transparent;
            gap: 1rem;
         }
      `}
`

const ArrowButton = (props) => (
   <Wrapper arrow {...props}>
      {props.children} <IconArrow />
   </Wrapper>
)

export default ArrowButton
