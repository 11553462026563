import React from 'react'
import styled from 'styled-components'
import Container from '../../atoms/Container/Container'
import Heading from '../../atoms/Heading/Heading'
import SectionHeader from '../../atoms/SectionHeader/SectionHeader'
import Faq from '../../molecules/Faq/Faq'

const Wrapper = styled.section`
   padding: 7.5rem 0;
   background-color: ${({ theme }) => theme.color.grayLight};
   @media (max-width: ${({ theme }) => theme.breakpoint.sm}) {
      padding: 5rem 0;
   }
   @media (max-width: 768px) and (orientation: landscape) {
      padding: 5rem 0;
   }
`
const FaqWrapper = styled.div`
   padding-top: 3rem;
   max-width: 800px;
   margin: 0 auto;
`

const FaqHome = () => (
   <Wrapper id="faq">
      <Container>
         <SectionHeader center>
            <Heading as="h2" size="h2">
               FAQ
            </Heading>
         </SectionHeader>

         <FaqWrapper>
            <Faq
               items={[
                  {
                     title: 'Czy mogę dodać do arkusza więcej kolumn?',
                     content:
                        'Tak, liczba i kolejność kolumn nie ma znaczenia. Możesz dodać kolejne kolumny, które są dla Ciebie istotnie np. zawierające informacje o nagłówkach lub meta tagach. Jedyne o czym musisz pamiętać to nie zmieniać nazw dla kolumn "ID", "Nazwa", "Anchor".',
                  },
                  {
                     title: 'Czy mogę stworzyć swój arkusz zamiast kopiować szablon?',
                     content:
                        'Tak, arkusz z szablonem jest tylko materiałem pomocniczym, równie dobrze możesz użyć dowolnego arkusza Google Sheets.',
                  },
                  {
                     title: 'W jaki sposób dane są zaciągane z arkusza?',
                     content:
                        'Do pobierania danych z arkusza korzystam z gotowego API, którego dokumentacja znajduje się pod <a href="https://github.com/benborgers/opensheet" target="_blank" rel="noreferrer noopener">tym linkiem</a>.',
                  },
                  {
                     title: 'Odświeżyłem Drzewko, ale dane się nie zaktualizowały, dlaczego?',
                     content:
                        'API, z którego korzystam trzyma dane w cache przez 30 sekund. Tutaj info od autora "Responses are cached for 30 seconds in order to improve performance and to avoid hitting Google Sheets’ rate limits, so it might take up to 30 seconds for fresh edits to show up in the API response."',
                  },
                  {
                     title: 'Czy mogę wysłać wygenerowane Drzewko klientowi?',
                     content:
                        'Tak, wystarczy, że skopiujesz link i prześlesz go dalej.',
                  },
                  {
                     title: 'Czy Drzewko można wygenerować tylko za pomocą formularza?',
                     content:
                        'Nie, możesz je wygenerować również wklejając ID formularza od razu do linka. Link powinien mieć formę drzewko.io/id/tutaj-id-formularza.',
                  },
                  {
                     title: 'Czy narzędzie jest bezpłatne?',
                     content: 'Tak, Drzewko jest w pełni darmowe.',
                  },
                  {
                     title: 'Czy są jakieś limity wygenerowanych Drzewek?',
                     content:
                        'Nie, nie ma żadnych limitów. Dopóki narzędzie działa można z niego korzystać. Nie testowałem jednak tego dla wiekszej ilości jednoczesnych odpytań API :)',
                  },
               ]}
            />
         </FaqWrapper>
      </Container>
   </Wrapper>
)

export default FaqHome
