import * as React from 'react'
import styled from 'styled-components'
import { useState, useContext } from 'react'
import { navigate } from 'gatsby'
import { myContext } from '../../../../provider'
import { fetchData } from '../../../assets/functions'
import Button from '../../atoms/Button/Button'
import Input from '../../atoms/Input/Input'
import Info from '../../molecules/Info/Info'
import ErrorBox from '../../atoms/ErrorBox/ErrorBox'

const StyledButton = styled(Button)`
   display: block;
   width: 100%;
`
const Wrapper = styled.form`
   display: flex;
   flex-direction: column;
   gap: 1rem;
`

const InputWrapper = styled.div`
   width: 100%;
   position: relative;
   margin-bottom: 1rem;
`

const InfoWrapper = styled.div`
   position: absolute;
   right: 1rem;
   top: 50%;
   transform: translateY(-50%);
`

const StyledLink = styled.a`
   font-size: 14px;
   width: 100%;
   text-align: center;
   display: block;
   margin-bottom: 1rem;
`
const Form = () => {
   const testContext = useContext(myContext)
   const [id, setId] = useState('')
   const [error, setError] = useState(false)
   const [loading, setLoading] = useState(false)
   const handleChange = (e) => {
      setId(e.target.value)
   }
   const handleSubmit = async (e) => {
      setLoading(true)
      setError(false)
      e.preventDefault()
      const data = await fetchData(id)
      if (data) {
         navigate(`/id/${id}`)
         testContext.changeMenu({
            ...testContext.menu,
            data: data,
            source: 'form',
         })
         setError(false)
      } else {
         setError(true)
      }
      setLoading(false)
   }
   return (
      <Wrapper onSubmit={handleSubmit}>
         <InputWrapper>
            <Input
               type="text"
               value={id}
               placeholder="Wklej ID arkusza"
               onChange={handleChange}
               required
            />
            <InfoWrapper>
               <Info
                  icon
                  content="Pamiętaj, że arkusz musi być udostępniony jako publiczny."
               />
            </InfoWrapper>
         </InputWrapper>

         <StyledButton as="input" type="submit" value="Stwórz drzewko" />
         <StyledLink
            href="https://docs.google.com/spreadsheets/d/1drjUpfh3Ks2wzzFQtQ_YwIkL-VzDkbNLsxHfhfS_Wd4/edit?usp=sharing"
            target="_blank"
            rel="noreferrer noopener"
         >
            Zobacz szablon arkusza
         </StyledLink>
         {loading && 'ładowanie...'}
         {error && (
            <ErrorBox>
               Coś poszło nie tak. Upewnij się, że ID arkusza jest poprawne
               oraz, że jest on udostępniony jako publiczny.
            </ErrorBox>
         )}
      </Wrapper>
   )
}

export default Form
