import React from 'react'
import { Link } from 'gatsby'

import styled from 'styled-components'
import Container from '../../atoms/Container/Container'
import Heading from '../../atoms/Heading/Heading'
import Paragraph from '../../atoms/Paragraph/Paragraph'
import IconTree from '../../atoms/Icons/IconTree'
import Box from '../../atoms/Box/Box'
import TabsHome from '../TabsHome/TabsHome'
import ArrowButton from '../../atoms/ArrowButton/ArrowButton'

const Wrapper = styled.section`
   padding: 0 0 0 0;
`
const InnerWrapper = styled.div`
   display: flex;
   align-items: center;
   justify-content: space-between;
   gap: 4rem;

   @media (max-width: ${({ theme }) => theme.breakpoint.xl}) {
      & > svg {
         width: 40%;
      }
   }
   @media (max-width: 1050px) {
      gap: 4rem;
      & > svg {
         width: 35%;
      }
   }
   @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
      & > svg {
         display: none;
      }
   }
`

const StyledBox = styled(Box)`
   position: relative;
   z-index: 80;
`

const Content = styled.div`
   display: flex;
   flex-direction: column;
   gap: 2rem;
`
const PreviewWrapper = styled.div`
   padding: 15rem 0 7.5rem 0;
   background-color: ${({ theme }) => theme.color.black};
   margin-top: -6rem;
   @media (max-width: ${({ theme }) => theme.breakpoint.sm}) {
      padding: 11rem 0 5rem 0;
   }
`

const MainBox = ({ children, title }) => (
   <Wrapper id="czym-jest-drzewko">
      <Container>
         <StyledBox big>
            <InnerWrapper>
               <Content>
                  <Heading>{title}</Heading>

                  <Paragraph>{children}</Paragraph>
                  <Link to="/id/1drjUpfh3Ks2wzzFQtQ_YwIkL-VzDkbNLsxHfhfS_Wd4">
                     <ArrowButton as="button" text secondary>
                        Zobacz demo
                     </ArrowButton>
                  </Link>
               </Content>
               <IconTree />
            </InnerWrapper>
         </StyledBox>
      </Container>
      <PreviewWrapper>
         <Container>
            <TabsHome />
         </Container>
      </PreviewWrapper>
   </Wrapper>
)

export default MainBox
