import React from 'react'

const IconArrow = () => (
   <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
   >
      <path
         d="M11.4883 1L18.0741 7.58579C18.8551 8.36684 18.8551 9.63317 18.0741 10.4142L11.4883 17"
         stroke="#FFFDFD"
         strokeWidth="2"
      />
      <path d="M19.3627 8.87744L1 9" stroke="#FFFDFD" strokeWidth="2" />
   </svg>
)

export default IconArrow
