import React from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import Tabs from '../Tabs/Tabs'
import TabsItem from '../../molecules/TabsItem/TabsItem'
import Heading from '../../atoms/Heading/Heading'
import Paragraph from '../../atoms/Paragraph/Paragraph'
import CreateTreeButton from '../../atoms/CreateTreeButton/CreateTreeButton'

const Wrapper = styled.div`
   display: grid;
   grid-template-columns: 1fr 1fr;
   gap: 4rem;
   padding: 0 5rem;
   align-items: center;
   @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
      padding: 0 3rem;
   }
   @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
      grid-template-columns: 1fr;
   }
   @media (max-width: ${({ theme }) => theme.breakpoint.sm}) {
      padding: 0 2rem;
   }
`
const ContentWrapper = styled.div``
const ImageWrapper = styled.div``
const StyledParagraph = styled(Paragraph)`
   margin: 2rem 0;
`
const TabsHome = () => (
   <Tabs navItems={['Desktop', 'Mobile', 'Struktura']}>
      <TabsItem>
         <Wrapper>
            <ContentWrapper>
               <Heading as="h3" size="h3" color="white">
                  Widok desktopowy
               </Heading>
               <StyledParagraph color="white">
                  Dostępne są dwie wersje nawigacji na desktopie. Menu kaskadowe
                  oraz mega menu. Dzięki temu łatwiej dopasować nawigację do
                  przygotowanego drzewa kategorii.
               </StyledParagraph>

               <CreateTreeButton secondary text arrow>
                  Stwórz drzewko
               </CreateTreeButton>
            </ContentWrapper>
            <ImageWrapper>
               <StaticImage
                  src="../../../assets/images/desktop.png"
                  alt="desktop"
               />
            </ImageWrapper>
         </Wrapper>
      </TabsItem>
      <TabsItem>
         <Wrapper>
            <ContentWrapper>
               <Heading as="h3" size="h3" color="white">
                  Widok mobilny
               </Heading>
               <StyledParagraph color="white">
                  Projektując nawigację należy pamiętać o wersji mobilnej.
                  Niestety, klienci często o tym zapominają. Dzięki temu
                  widokowi pokażesz klientowi jak powinno wyglądać prawidłowo
                  skonstruowane menu na urządzeniach mobilnych.
               </StyledParagraph>
               <CreateTreeButton secondary text arrow>
                  Stwórz drzewko
               </CreateTreeButton>
            </ContentWrapper>
            <ImageWrapper>
               <StaticImage
                  src="../../../assets/images/mobile.png"
                  alt="mobile"
               />
            </ImageWrapper>
         </Wrapper>
      </TabsItem>
      <TabsItem>
         <Wrapper>
            <ContentWrapper>
               <Heading as="h3" size="h3" color="white">
                  Struktura drzewa
               </Heading>
               <StyledParagraph color="white">
                  Widok struktury pozwala zwizualizować zagnieżdżenia
                  występujące w nawigacji. Dzięki temu drzewo kategorii stanie
                  się jeszcze bardziej czytelne i łatwiejsze do zrozumienia.
               </StyledParagraph>
               <CreateTreeButton secondary text arrow>
                  Stwórz drzewko
               </CreateTreeButton>
            </ContentWrapper>
            <ImageWrapper>
               <StaticImage
                  src="../../../assets/images/structure.png"
                  alt="structure"
               />
            </ImageWrapper>
         </Wrapper>
      </TabsItem>
   </Tabs>
)

export default TabsHome
