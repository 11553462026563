import React from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import Heading from '../../atoms/Heading/Heading'
import Paragraph from '../../atoms/Paragraph/Paragraph'
import Browser from '../../molecules/Browser/Browser'
import CreateTreeButton from '../../atoms/CreateTreeButton/CreateTreeButton'

const Wrapper = styled.div`
   padding-top: 7.5rem;
   @media (max-width: ${({ theme }) => theme.breakpoint.sm}) {
      padding-top: 5rem;
   }
   @media (max-width: 768px) and (orientation: landscape) {
      padding-top: 5rem;
   }
`
const HalfSplit = styled.div`
   display: flex;
   align-items: center;
   flex-wrap: wrap;
   &:not(:last-child) {
      margin-bottom: 7.5rem;
      @media (max-width: ${({ theme }) => theme.breakpoint.sm}) {
         margin-bottom: 5rem;
      }
      @media (max-width: 768px) and (orientation: landscape) {
         margin-bottom: 5rem;
      }
   }
`
const ContentWrapper = styled.div`
   flex: 1;
   padding-left: ${({ left }) => (left ? '0' : '7.5rem')};
   padding-right: ${({ left }) => (left ? '7.5rem' : '0')};
   @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
      min-width: 100%;
      padding: 0;
      order: 1;
      margin-bottom: 4rem;
   }
`
const ImageWrapper = styled.div`
   flex: 1;
   min-width: 50%;
   @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
      min-width: 100%;
      order: 2;
   }
`
const StyledHeading = styled(Heading)`
   margin-bottom: 2rem;
`
const ButtonWrapper = styled.div`
   display: flex;
   justify-content: center;
   margin-top: 7.5rem;
`

const Tips = () => (
   <Wrapper>
      <HalfSplit>
         <ImageWrapper>
            <Browser shadow>
               <StaticImage
                  src="../../../assets/images/prepare-sheet.png"
                  alt="przygotuj arkusz"
               />
            </Browser>
         </ImageWrapper>
         <ContentWrapper>
            <StyledHeading as="h3" size="h3">
               Przygotuj arkusz
            </StyledHeading>
            <Paragraph>
               Skopiuj{' '}
               <a
                  href="https://docs.google.com/spreadsheets/d/1drjUpfh3Ks2wzzFQtQ_YwIkL-VzDkbNLsxHfhfS_Wd4/edit?usp=sharing"
                  target="_blank"
                  rel="noreferrer noopener"
               >
                  ten arkusz
               </a>
               . Nie zmieniaj nagłówków kolumn i pamiętaj, że każda kategoria
               musi mieć swoje unikalne ID. Kategorie oznacz poziomem
               zagnieżdżenia i dodawaj je z zachowaniem kolejności i hierarchii.
            </Paragraph>
         </ContentWrapper>
      </HalfSplit>
      <HalfSplit>
         <ContentWrapper left>
            <StyledHeading as="h3" size="h3">
               Udostępnij arkusz
            </StyledHeading>
            <Paragraph>
               Udostępnij arkusz jako "Każdy użytkownik internetu mający ten
               link może wyświetlać". Bez tego Drzewko nie zadziała.
            </Paragraph>
         </ContentWrapper>
         <ImageWrapper>
            <Browser shadow>
               <StaticImage
                  src="../../../assets/images/copy-sheet.png"
                  alt="skopiuj arkusz"
               />
            </Browser>
         </ImageWrapper>
      </HalfSplit>
      <HalfSplit>
         <ImageWrapper>
            <Browser shadow>
               <StaticImage
                  src="../../../assets/images/copy-id.png"
                  alt="przygotuj arkusz"
               />
            </Browser>
         </ImageWrapper>
         <ContentWrapper>
            <StyledHeading as="h3" size="h3">
               Skopiuj ID i wklej do formularza
            </StyledHeading>
            <Paragraph>
               Skopiuj ID wcześniej udostępnionego arkusza. Znajdziesz je w
               pasku przeglądarki. Skopiowane ID wklej do formularza i wygeneruj
               swoje Drzewko.
            </Paragraph>
         </ContentWrapper>
      </HalfSplit>
      <ButtonWrapper>
         <CreateTreeButton arrow>Stwórz drzewko</CreateTreeButton>
      </ButtonWrapper>
   </Wrapper>
)

export default Tips
