import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
   overflow: hidden;
   position: relative;
   border-radius: 12px;
   border: 2px solid ${({ theme }) => theme.color.primaryLight};
   width: 100%;
   background-color: ${({ theme }) => theme.color.grayLight};
   box-shadow: ${({ theme, shadow }) => (shadow ? theme.shadow1.normal : '')};
`

const Header = styled.div`
   padding: 0.5rem;
   background-color: ${({ theme }) => theme.color.white};
   position: relative;
   display: flex;
   gap: 0.5rem;
   align-items: center;
   width: 100%;
   border-bottom: 2px solid ${({ theme }) => theme.color.primaryLight};
`
const Dot = styled.span`
   height: 0.5rem;
   width: 0.5rem;
   border-radius: 0.5rem;
   background-color: ${({ theme }) => theme.color.gray};
`

const InnerWrapper = styled.div`
   padding: 1.5rem;
`

const Browser = ({ children, shadow }) => (
   <Wrapper shadow>
      <Header>
         <Dot />
         <Dot />
         <Dot />
      </Header>
      <InnerWrapper>{children}</InnerWrapper>
   </Wrapper>
)

export default Browser
