import React, { useState } from 'react'
import styled from 'styled-components'
import TabsNav from '../../molecules/TabsNav/TabsNav'
import PropTypes from 'prop-types'

const Wrapper = styled.div`
   width: 100%;
   color: ${({ theme }) => theme.color.white};
`
const Content = styled.div`
   width: 100%;
`
const Tabs = ({ navItems, children }) => {
   const [active, setActive] = useState(0)
   const clickHandler = (e) => {
      setActive(Number(e.currentTarget.id))
   }
   return (
      <Wrapper>
         <TabsNav items={navItems} clickFn={clickHandler} active={active} />
         <Content>
            {children.map((child, index) => (
               <React.Fragment key={index}>
                  {React.cloneElement(child, { active, index })}
               </React.Fragment>
            ))}
         </Content>
      </Wrapper>
   )
}

export default Tabs

TabsNav.propTypes = {
   items: PropTypes.array.isRequired,
}
