import React from 'react'
import styled from 'styled-components'
import FaqItem from '../FaqItem/FaqItem'

const Wrapper = styled.div`
   display: grid;
   grid-template-columns: ${({ split }) => (split ? '1fr 1fr' : '1fr')};
   gap: ${({ numbers }) => (numbers ? '1rem' : '2rem')};
   @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
      grid-template-columns: 1fr;
   }
`
const Split = styled.div`
   display: flex;
   gap: 2rem;
   flex-direction: column;
`

const Faq = ({ items, split, numbers }) => {
   const middle = Math.ceil(items.length / 2)
   const left = items.slice(0, middle)
   const right = items.slice(middle)
   return (
      <Wrapper numbers={numbers} split={split}>
         {split ? (
            <>
               <Split>
                  {left.map((item, index) => (
                     <FaqItem
                        number={index + 1}
                        numbers={numbers}
                        key={index}
                        title={item.title}
                     >
                        {item.content}
                     </FaqItem>
                  ))}
               </Split>
               <Split>
                  {right.map((item, index) => (
                     <FaqItem
                        number={index + 1}
                        numbers={numbers}
                        key={index}
                        title={item.title}
                     >
                        {item.content}
                     </FaqItem>
                  ))}
               </Split>
            </>
         ) : (
            items.map((item, index) => (
               <FaqItem
                  number={index + 1}
                  numbers={numbers}
                  key={index}
                  title={item.title}
               >
                  {item.content}
               </FaqItem>
            ))
         )}
      </Wrapper>
   )
}

export default Faq
