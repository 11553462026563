import styled from 'styled-components'

const Input = styled.input`
   background-color: ${({ theme }) => theme.color.white};
   padding: 1rem 3.5rem 1rem 1rem;
   font-family: ${({ theme }) => theme.font};
   font-size: 1rem;
   border: 2px solid ${({ theme }) => theme.color.black};
   border-radius: 12px;
   width: 100%;
   &:focus {
      outline: none;
   }
`
export default Input
