import React from 'react'
import styled from 'styled-components'
import Heading from '../../atoms/Heading/Heading'
import Button from '../../atoms/Button/Button'
import { Link } from 'gatsby'

import CreateTreeButton from '../../atoms/CreateTreeButton/CreateTreeButton'

const HeroImageWrapper = styled.section`
   width: 100%;
   height: 100vh;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   gap: 3rem;
   @media (orientation: portrait) {
      max-height: 700px;
   }
   @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
      height: auto;
      padding: 7.5rem 0;
   }
   @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
      height: auto;
      padding: 4rem 0;
   }
   @media (max-width: 768px) and (orientation: landscape) {
      height: auto;
      padding: 4rem 0;
   }
`
const StyledHeading = styled(Heading)`
   max-width: 670px;
   text-align: center;
   @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
      max-width: 500px;
      padding: 0 2rem;
   }
`
const ButtonsWrapper = styled.div`
   display: flex;
   gap: 2rem;
   @media (max-width: ${({ theme }) => theme.breakpoint.sm}) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      & > *:first-child {
         order: 2;
      }
   }
`

const HeroImage = () => {
   return (
      <HeroImageWrapper>
         <StyledHeading>
            Zwizualizuj drzewo kategorii na podstawie Google Sheets
         </StyledHeading>
         <ButtonsWrapper>
            <Link to="/id/1drjUpfh3Ks2wzzFQtQ_YwIkL-VzDkbNLsxHfhfS_Wd4">
               <Button as="button" secondary>
                  Zobacz demo
               </Button>
            </Link>

            <CreateTreeButton arrow>Stwórz drzewko</CreateTreeButton>
         </ButtonsWrapper>
      </HeroImageWrapper>
   )
}

export default HeroImage
