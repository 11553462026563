import React from 'react'
import { useTheme } from 'styled-components'

const IconTree = () => {
   const theme = useTheme()
   return (
      <svg
         className="drop-shadow"
         width="444"
         height="232"
         viewBox="0 0 444 232"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
      >
         <path
            d="M189 54H136C129.373 54 124 59.3726 124 66V104C124 110.627 129.373 116 136 116H178.485"
            stroke="#D0CCCA"
            strokeWidth="2"
         />
         <path
            d="M342.491 22H308C301.373 22 296 27.3726 296 34V202C296 208.627 301.373 214 308 214H349"
            stroke="#D0CCCA"
            strokeWidth="2"
         />
         <path d="M296 85L345.5 85" stroke="#D0CCCA" strokeWidth="2" />
         <path d="M296 148L345.5 148" stroke="#D0CCCA" strokeWidth="2" />
         <path d="M124 85.5H99" stroke="#D0CCCA" strokeWidth="2" />
         <path d="M297.5 55L270 55" stroke="#D0CCCA" strokeWidth="2" />
         <rect
            x="172"
            y="98"
            width="100"
            height="40"
            rx="20"
            fill={theme.color.white}
            className="drop-shadow"
         />
         <rect
            x="344"
            width="100"
            height="40"
            rx="20"
            fill={theme.color.white}
            className="drop-shadow"
         />
         <rect
            x="344"
            y="192"
            width="100"
            height="40"
            rx="20"
            fill={theme.color.white}
            className="drop-shadow"
         />
         <rect
            x="344"
            y="64"
            width="100"
            height="40"
            rx="20"
            fill={theme.color.white}
            className="drop-shadow"
         />
         <rect
            x="172"
            y="34"
            width="100"
            height="40"
            rx="20"
            fill={theme.color.primary}
            className="drop-shadow"
         />
         <rect
            x="344"
            y="128"
            width="100"
            height="40"
            rx="20"
            fill={theme.color.primary}
            className="drop-shadow"
         />
         <rect
            y="65"
            width="100"
            height="40"
            rx="20"
            fill={theme.color.primary}
            className="drop-shadow"
         />
      </svg>
   )
}

export default IconTree
