import React from 'react'
import { useTheme } from 'styled-components'
import PropTypes from 'prop-types'

const IconDropdownArrow = ({ color }) => {
   const theme = useTheme()
   return (
      <svg
         width="13"
         height="8"
         viewBox="0 0 13 8"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
      >
         <path
            d="M11.595 1L6.20911 6.38471L1 1.1756"
            stroke={theme.color[color]}
            strokeWidth="2"
         />
      </svg>
   )
}

export default IconDropdownArrow

IconDropdownArrow.propTypes = {
   color: PropTypes.string,
}

IconDropdownArrow.defaultProps = {
   color: 'white',
}
