import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Button from '../Button/Button'
import ArrowButton from '../ArrowButton/ArrowButton'
import Modal from '../../molecules/Modal/Modal'
import CreateTreeModal from '../../organisms/CreateTreeModal/CreateTreeModal'
import Portal from '../../../../portal'

const CreateTreeButton = ({ arrow, children, ...props }) => {
   const [open, setOpen] = useState(false)
   const clickHandler = () => setOpen(!open)
   return (
      <>
         {arrow ? (
            <ArrowButton onClick={clickHandler} {...props}>
               {children}
            </ArrowButton>
         ) : (
            <Button onClick={clickHandler} {...props}>
               {children}
            </Button>
         )}
         {open && (
            <Portal>
               <Modal big clickFn={clickHandler}>
                  <CreateTreeModal />
               </Modal>
            </Portal>
         )}
      </>
   )
}

export default CreateTreeButton

CreateTreeButton.propTypes = {
   arrow: PropTypes.bool,
}

CreateTreeButton.defaultProps = {
   arrow: false,
}
